import faker from 'faker';
const tileData = [];
for (let i = 0; i < 100; i++) {
  tileData[i] = {
    img: `/images/${Math.floor(Math.random() * 3) + 1}.jpeg`,
    title: faker.lorem.words(),
    author: faker.name.findName(),
    id: faker.random.uuid(),
    price: faker.commerce.price(),
    date: faker.date.future(),
    url: faker.internet.url(),
    content: faker.lorem.paragraphs(),
    expanded: false
  }
}

export default tileData;