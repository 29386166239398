
import React from 'react';
import Dimensions from 'react-dimensions';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Collapse from '@material-ui/core/Collapse';

import red from '@material-ui/core/colors/red';

import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Group from '@material-ui/icons/Group';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import MoreIcon from '@material-ui/icons/MoreVert';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';

import DeleteIcon from '@material-ui/icons/Delete';
import ArchiveIcon from '@material-ui/icons/Archive';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import tileData from './tileData';

const drawerWidth = 240;
const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: '#eeeeee',
  },
  appBar: {
    backgroundColor: theme.palette.common.black,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    //marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  gridList: {
    // width: '100%',
    height: '100%',
    backgroundColor: '#eeeeee',
    justifyContent: 'center',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  grow: {
    flexGrow: 1,
  },
  media: {
    height: 0,
    paddingTop: '66.66%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  button: {
    position: 'fixed',
    bottom: 70,
    right: 70,
    width: 70,
    height: 70,
    margin:0,
    zIndex: 1000,
  }
});

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */
class  TitlebarGridList extends React.Component {
  state = {
    open: false,
    tiles: tileData.reduce((accumulator, current) => {
      // console.log('ACC: ', accumulator);
      // console.log('K:', current);
      accumulator[current.id] = false;
      return accumulator;
    }, {})
  };

  handleDrawerOpen = () => {
    this.setState(state => ({ open: true, expanded: state.expanded }));
  };

  handleDrawerClose = () => {
    this.setState(state => ({ open: false, expanded: state.expanded }));
  };

  handleExpandClick = (key) => {
    console.log('CLICK: ', key);
    this.setState(state => {
      const t = state.tiles;
      t[key] = !t[key];
      return { open: state.open, tiles: t }
    });
  };


  render () {
    const columns = Math.floor(this.props.containerWidth / 340 );
    const { classes, theme } = this.props;
    const { open } = this.state;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <Button variant="fab" color="primary" aria-label="Add" className={classes.button}>
        <AddIcon />
      </Button>
        <AppBar
          position="fixed"
          // color={theme.palette.common.white}
          // backgroundColor={theme.palette.common.black}
          className={classNames(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar disableGutters={!open}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Typography className={classes.title} variant="h6" color="inherit" noWrap>
              <div style={{display: "flex", alignItems: 'center'}}>
              <img src="https://desa.pl/media/img/desa-unicum-logo.png" style={{width: 40, height: 40, padding: '0 10px', boxSizing: 'content-box'}}/>
              Aukcje Zagraniczne
              </div>
            </Typography>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Szukaj..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <IconButton
                // onClick={this.handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <IconButton
                // onClick={this.handleProfileMenuOpen}
                color="inherit"
              >
                <Group />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton /** aria-haspopup="true" onClick={this.handleMobileMenuOpen}*/ color="inherit">
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            {['Home', 'Obiekty', 'Aukcje'].map((text, index) => (
              <ListItem button key={text}>
                <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {['Licytowane', 'Archiwum', 'Grupy Robocze', 'Profil'].map((text, index) => (
              <ListItem button key={text}>
                <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          {/* <div className={classes.sectionDesktop}> */}
            <GridList cols={columns} style={{width: columns * 344}} cellHeight='auto' className={classes.gridList}>
              {/* <GridListTile key="Subheader" cols={columns} style={{ height: 'auto' }}>
                <ListSubheader component="div" style={{ fontSize: 30 }}>Obiekty</ListSubheader>
              </GridListTile> */}
              {this.renederTiles()}
            </GridList>
          {/* </div>
          <div className={classes.sectionMobile}>
            <GridList cols={1} cellHeight='auto' className={classes.gridList}>
              <GridListTile key="Subheader" cols={1} style={{ height: 'auto' }}>
                <ListSubheader component="div">Obiekty</ListSubheader>
              </GridListTile>
              {this.renederTiles()}
            </GridList>
          </div> */}
        </main>
      </div>
    );
  }

  renederTiles () {
    const { classes, theme } = this.props;
    const { open, tiles } = this.state;

    return tileData.map(tile => (
      <GridListTile key={tile.id} style={{width: 340}}>
         <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar aria-label="Sotheby's" title="Sotheby's" className={classes.avatar}>
                  S
                </Avatar>
              }
              action={
                <IconButton>
                  <MoreVertIcon />
                </IconButton>
              }
              title={tile.title}
              subheader={`Cena wywoławcza: $${tile.price}`}
            />
            <CardMedia
              className={classes.media}
              image={tile.img}
              title={tile.title}
            />
            <CardContent>
              <Typography component="div">
                <div>
                  Miejsce aukcji: <b>Sotheby's, London</b><br/>
                  Autor: <b>{tile.author}</b><br/>
                  Link: <b>{tile.url}</b><br/>
                </div>
              </Typography>
            </CardContent>
            <CardActions className={classes.actions} disableActionSpacing>
              <IconButton aria-label="Delete">
                <DeleteIcon />
              </IconButton>
              <IconButton aria-label="Archive">
                <ArchiveIcon />
              </IconButton>
              <IconButton
                className={classNames(classes.expand, {
                  [classes.expandOpen]: this.state.tiles[tile.id],
                })}
                onClick={this.handleExpandClick.bind(this, tile.id)}
                aria-expanded={this.state.tiles[tile.id]}
                aria-label="Show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
            <Collapse in={this.state.tiles[tile.id]} timeout="auto" unmountOnExit>
              <CardContent>
                <Typography paragraph>{tile.content}</Typography>
              </CardContent>
            </Collapse>
        </Card>
      </GridListTile>
    ))
  }
}


TitlebarGridList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(TitlebarGridList);